import { createSelector } from '@ngrx/store';

import { ConventionalIODevice, ConventionalIOSignal, Element, IOPin, Riom } from '@priva/next-model';

import { hasIOPinSameIOSignal, IOSignalDetail } from './io-mapping.model';
import { IOMappingStateContainer } from './io-mapping.state';

const mapToIoSignalDetail = (
    ioSignal: ConventionalIOSignal,
    ioDevice: ConventionalIODevice,
    elements: Element[],
    rioms: Riom[],
): IOSignalDetail => {
    const element = elements?.find((d) => d.id === ioDevice.equipment.id);
    let ioPin: IOPin;
    const riom = rioms?.find((r) => {
        ioPin = r.ioPins.find(
            (pin) => pin.protocolMapping && hasIOPinSameIOSignal(pin.protocolMapping, ioSignal, ioDevice.id),
        );
        return !!ioPin;
    });

    return { ...ioSignal, ioDevice, element, riom, ioPin };
};

const mapToIoSignalDetails = (
    devices: ConventionalIODevice[],
    elements: Element[],
    rioms: Riom[],
): IOSignalDetail[] => {
    const ioSignalDetails = [];
    devices.forEach((ioDevice) => {
        const ioSignals = ioDevice.ioSignals.map((ioSignal) =>
            mapToIoSignalDetail(ioSignal, ioDevice, elements, rioms),
        );
        ioSignalDetails.push(...ioSignals);
    });
    return ioSignalDetails;
};

export const selectIOSignalDetails = createSelector(
    (state: IOMappingStateContainer) => state.ioMapping.ioDevices,
    (state: IOMappingStateContainer) => state.ioMapping.elementsForRiom,
    (state: IOMappingStateContainer) => state.ioMapping.rioms,
    (devices: ConventionalIODevice[], elements: Element[], rioms: Riom[]): IOSignalDetail[] => {
        if (!elements || !devices || !rioms) {
            return [];
        }
        return mapToIoSignalDetails(devices, elements, rioms);
    },
);

export const selectIOSignalDetailsEditableRiom = createSelector(
    (state: IOMappingStateContainer) => state.ioMapping.ioDevices,
    (state: IOMappingStateContainer) => state.ioMapping.elementsForRiom,
    (state: IOMappingStateContainer) => state.ioMapping.rioms,
    (state: IOMappingStateContainer) => state.ioMapping.activeRiom,
    (
        devices: ConventionalIODevice[],
        elements: Element[],
        rioms: Riom[],
        activeRiom: Riom,
    ): IOSignalDetail[] => {
        if (!elements || !devices || !rioms || !activeRiom) {
            return [];
        }
        const filteredRioms = rioms.filter((riom) => riom.id !== activeRiom.id) || [];
        return mapToIoSignalDetails(devices, elements, [activeRiom, ...filteredRioms]);
    },
);
