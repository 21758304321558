import {
    ConventionalIODevice,
    ConventionalIOSignal,
    Element,
    IOPin,
    IOPinProtocolMapping,
    Riom,
} from '@priva/next-model';

export interface IOSignalDetail extends ConventionalIOSignal {
    element?: Element;
    ioDevice?: ConventionalIODevice;
    riom?: Riom;
    ioPin?: IOPin;
    group?: string;
    disabled?: boolean;
}

export function hasIOPinSameIOSignal(
    protocolMapping: IOPinProtocolMapping,
    ioSignal: ConventionalIOSignal,
    ioSignalIoDeviceId: string,
): boolean {
    return (
        protocolMapping &&
        ioSignal &&
        protocolMapping.ioSignalId === ioSignal.id &&
        protocolMapping.deviceId === ioSignalIoDeviceId
    );
}

export function isSameIOSignal(ioSignal1: IOSignalDetail, ioSignal2: IOSignalDetail): boolean {
    return ioSignal1.id === ioSignal2.id && ioSignal1.ioDevice.id === ioSignal2.ioDevice.id;
}
