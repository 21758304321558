import { ConventionalIODevice, Element, Riom } from '@priva/next-model';

export interface IOMappingStateContainer {
    ioMapping: IOMappingState;
}
export interface IOMappingState {
    activeRiom?: Riom;
    rioms: Riom[];
    riomIds: string[];
    ioDevices: ConventionalIODevice[];
    elementsForRiom: Element[];
}

export const initialIOMappingState: IOMappingState = {
    rioms: [],
    riomIds: [],
    ioDevices: [],
    activeRiom: undefined,
    elementsForRiom: [],
};
