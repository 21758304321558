import { createAction, props } from '@ngrx/store';

import {
    ConventionalIODevice,
    Element,
    IOPinProtocolMapping,
    PinValidationType,
    Riom,
} from '@priva/next-model';

export const initIOMappingState = createAction('[IO-Mapping] Init IO mapping state');

export const resetState = createAction('[IO-Mapping] Reset to init state');

export const getRiom = createAction('[IO-Mapping API] Get Riom', props<{ riomId: string }>());
export const getRiomSuccess = createAction('[IO-Mapping API] Get Riom (success)', props<{ riom: Riom }>());
export const getEditableRiom = createAction(
    '[IO-Mapping API] Get editable Riom',
    props<{ riomId: string }>(),
);
export const getEditableRiomSuccess = createAction(
    '[IO-Mapping API] Get editable Riom (success)',
    props<{ riom: Riom }>(),
);

export const getElementForRiom = createAction(
    '[IO-Mapping API] Get element for Riom',
    props<{ elementId: string }>(),
);
export const getElementForRiomSuccess = createAction(
    '[IO-Mapping API] Get element for Riom (success)',
    props<{ element: Element }>(),
);

export const getElementsFromZoneIds = createAction(
    '[IO-Mapping] Get elements from zone ids',
    props<{ zoneIds: string[] }>(),
);
export const getElementsForRiomFromZone = createAction(
    '[IO-Mapping API] Get elements for Riom from specific zone',
    props<{ zoneId: string }>(),
);
export const getElementsForRiomFromZoneSuccess = createAction(
    '[IO-Mapping API] Get elements for Riom from specific zone (success)',
    props<{ zoneId: string; elements: Element[] }>(),
);

export const getElementsForRiomByElementIds = createAction(
    '[IO-Mapping API] Get elements for Riom by element ids',
    props<{ elementIds: string[] }>(),
);
export const getElementsForRiomByElementIdsSuccess = createAction(
    '[IO-Mapping API] Get elements for Riom by element ids (success)',
    props<{ elements: Element[] }>(),
);

export const applyRiomMapping = createAction('[IO-Mapping API] Apply Riom mapping', props<{ riom: Riom }>());
export const applyRiomMappingSuccess = createAction(
    '[IO-Mapping API] Apply Riom mapping (success)',
    props<{ riom: Riom }>(),
);

export const selectEditableRiom = createAction(
    '[IO-Mapping] Select editable Riom',
    props<{ riomId: string }>(),
);
export const clearEditableRiom = createAction('[IO-Mapping] Clear editable Riom state');
export const updateEditableRiom = createAction('[IO-Mapping] Update editable Riom', props<{ riom: Riom }>());
export const cancelIOMapping = createAction('[IO-Mapping] Cancel IO mapping', props<{ riom: Riom }>());

export const getConventionalIoDevice = createAction(
    '[IO-Mapping API] Get conventional IO device',
    props<{ ioDeviceId: string }>(),
);
export const getConventionalIoDeviceSuccess = createAction(
    '[IO-Mapping API] Get conventional IO device (success)',
    props<{ ioDevice: ConventionalIODevice }>(),
);

export const getConventionalIoDevicesInZone = createAction(
    '[IO-Mapping API] Get conventional IO devices in zone',
    props<{ zoneId: string }>(),
);
export const getConventionalIoDevicesInZoneSuccess = createAction(
    '[IO-Mapping API] Get conventional IO devices in zone (success)',
    props<{ zoneId: string; ioDevices: ConventionalIODevice[] }>(),
);

export const getConventionalIoDevicesRiom = createAction(
    '[IO-Mapping API] Get conventional IO devices from specific riom',
    props<{ riomId: string }>(),
);
export const getConventionalIoDevicesRiomSuccess = createAction(
    '[IO-Mapping API] Get conventional IO devices from specific riom (success)',
    props<{ ioDevices: ConventionalIODevice[] }>(),
);

export const getConventionalIoDevicesEquipment = createAction(
    '[IO-Mapping API] Get conventional IO devices from specific equipment element',
    props<{ equipmentId: string }>(),
);
export const getConventionalIoDevicesEquipmentSuccess = createAction(
    '[IO-Mapping API] Get conventional IO devices from specific  equipment element (success)',
    props<{ equipmentId: string; ioDevices: ConventionalIODevice[] }>(),
);

export const openIOMappingDialog = createAction(
    '[IO-Mapping] Open IO mapping dialog',
    props<{ riomElement: Element; selectedZoneId: string; selectedDeviceId: string }>(),
);

export const updateStateAfterRemoveDevice = createAction(
    '[IO-Mapping] Update state after remove device',
    props<{ equipmentId: string }>(),
);

export const updateStateAfterRemoveDevices = createAction(
    '[IO-Mapping] Update state after remove devices',
    props<{ equipments: Element[] }>(),
);

export const updateIOPinProtocolMapping = createAction(
    '[IO-Mapping] Update IO pin binding',
    props<{ ioPinId: string; ioPinProtocolMapping: IOPinProtocolMapping }>(),
);

export const updateRiomIOPinValidation = createAction(
    '[IO-Mapping] Update Riom IO Pin validation',
    props<{ riomId: string; ioPinProtocolMappingId: string; status: PinValidationType; note: string }>(),
);
export const updateRiomIOPinValidationSuccess = createAction(
    '[IO-Mapping] Update Riom IO Pin validation (success)',
    props<{ riomId: string; ioPinProtocolMappingId: string; status: PinValidationType; note: string }>(),
);
